import React, { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { twoFractionDigitsFormatter, listingColumns } from '../../utils'

import { fetchClients, fetchListingData } from '../../modules/clients/actions'

import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'

import PageTitle from 'components/PageTitle'

import { api } from 'components/PageTitle'
import { Link } from 'react-router-dom'
import Filter from '../Filter/Filter'
import WidgetTable from '../../components/WidgetTable'
import Loading from '../../components/Loading'

class Listing extends Component {
  constructor(props) {
    super(props)

    this.init = this.init.bind(this)
  }

  componentDidMount() {
    this.props.fetchClients().then(() => {
      this.init()
    })
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(this.props.filterValues, prevProps.filterValues)) {
      if (this.props.clients !== undefined) {
        this.init()
      }
    }
  }

  init() {
    Object.values(this.props.clients).map((client, index) => {
      this.props.fetchListingData(
        client.slug,
        this.props.filterValues.startDate,
        this.props.filterValues.endDate
      )
    })
  }

  render() {
    let atLeastOneisFetching = Object.values(this.props.isFetching).reduce(
      (result, isFetching) => result || isFetching,
      false
    )
    return (
      <div id="overview_page">
        <div className="w-container">
          <div className="w-title-wrap">
            <PageTitle title="Overview" />
            <Link
              to="/client/create"
              className="nav-item nav-link w-button w-title-button"
              style={{ color: 'white' }}
            >
              + New Client
            </Link>
          </div>
        </div>
        <Filter />
        <div className="w-container pb-5">
          {this.props.listingData === undefined ||
          this.props.listingData === null ||
          _isEmpty(this.props.listingData) ||
          atLeastOneisFetching ? (
            <Loading />
          ) : (
            <WidgetTable
              columns={listingColumns}
              data={Object.keys(this.props.listingData).map((key, index) => {
                return {
                  ...this.props.listingData[key],
                  slug: key
                }
              })}
              sortingOptions={[{ id: 'client', desc: false }]}
            />
          )}
        </div>
      </div>
    )
  }
}

Listing.propTypes = {
  filterValues: PropTypes.object,
  isFetching: PropTypes.object,
  listingData: PropTypes.object,
  clients: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients: state.clients.entities.clients,
    listingData: state.clients.listing.data,
    isFetching: state.clients.listing.isFetching,
    filterValues: state.filter.applied
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchClients: () => dispatch(fetchClients()),
    fetchListingData: (clientSlug, startDate, endDate) =>
      dispatch(fetchListingData(clientSlug, startDate, endDate))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Listing)
)
