import React, { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { capitalize, detailColumns } from '../../utils/index'

import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'

import { fetchDetailData } from '../../modules/clients/actions'

import PageTitle from '../../components/PageTitle'

import Filter from '../Filter/Filter'
import Loading from '../../components/Loading'
import WidgetTable from '../../components/WidgetTable'
import { api, getTodayDate } from '../../utils/index'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'

class Detail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      brokenRules: [],
      bankMovements: []
    }

    this.init = this.init.bind(this)
    this.clientSetup = this.clientSetup.bind(this)
    this.bankMovementSetup = this.bankMovementSetup.bind(this)
  }

  clientSetup() {
    this.setState(() => ({
      brokenRules: []
    }))
    api
      .get('client-alerts/?client_slug=' + this.props.match.params.clientSlug)
      .then(results => {
        if (results.data.hasOwnProperty('brokenRules')) {
          Object.values(results.data.brokenRules).map(rule => {
            this.setState((state, props) => {
              let brokenRules = state.brokenRules
              const { alertId, alertMessage } = rule
              brokenRules.push({ alertId, alertMessage })
              return { brokenRules }
            })
          })
        }
      })
    this.bankMovementSetup()
  }

  bankMovementSetup() {
    let bankMovements = []
    api
      .get(
        `bank-data/?client=${this.props.match.params.clientSlug}&from=${this.props.filterValues.startDate}&to=${this.props.filterValues.endDate}`
      )
      .then(results => {
        if (results.data.hasOwnProperty('bank_movements')) {
          Object.values(results.data.bank_movements).map(movement => {
            this.setState((state, props) => {
              const data = {
                day: movement.day,
                value: movement.value,
                bill_id: movement.bill.bill_id
              }
              bankMovements.push(movement)
              // return {bankMovements}
            })
          })
        }
        this.setState(() => ({
          bankMovements
        }))
      })
  }

  componentDidMount() {
    this.init()
    this.clientSetup()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_isEqual(this.props.filterValues, prevProps.filterValues) ||
      !_isEqual(this.props.clientData, prevProps.clientData) ||
      !_isEqual(
        this.props.match.params.clientSlug,
        prevProps.match.params.clientSlug
      )
    ) {
      this.init()
      this.clientSetup()
    }
  }

  init() {
    this.props.fetchDetailData(
      this.props.filterValues.aggregationLevel,
      this.props.match.params.clientSlug,
      this.props.filterValues.startDate,
      this.props.filterValues.endDate
    )
  }

  render() {
    return (
      <div id="detail_page">
        <div className="w-container">
          <div className="w-title-wrap">
            <PageTitle
              title={
                !_isEmpty(this.props.clients)
                  ? capitalize(
                      Object.values(this.props.clients).find(
                        x => x.slug === this.props.match.params.clientSlug
                      ).name
                    )
                  : ''
              }
            />
            <Link
              to={'/client/' + this.props.match.params.clientSlug}
              className="w-button w-title-button"
            >
              Edit Client
            </Link>
          </div>
        </div>

        <Filter />

        <div className="w-container pb-5">
          {this.props.clientData[this.props.match.params.clientSlug] ===
            undefined ||
          this.props.clientData[this.props.match.params.clientSlug] === null ||
          this.props.isFetching === true ? (
            <Loading />
          ) : (
            <WidgetTable
              columns={detailColumns}
              data={Object.values(
                this.props.clientData[this.props.match.params.clientSlug]
              ).map((item, index) => item)}
            />
          )}
          <div
            className="well"
            style={{ padding: `$spacer`, marginTop: '20px', textAlign: 'left' }}
          >
            {Object.values(this.state.brokenRules).map(rule => (
              <div
                key={rule.alertId}
                className="alert alert-warning"
                role="alert"
              >
                <strong>Rule violation ({getTodayDate()})</strong>:{' '}
                {rule.alertMessage}
              </div>
            ))}
          </div>
          {this.state.bankMovements.length !== 0 ? (
            <div
              className="well"
              style={{
                padding: `$spacer`,
                marginTop: '20px',
                textAlign: 'left'
              }}
            >
              <strong> Bank movements:</strong>
              <ul>
                {Object.values(this.state.bankMovements).map(movement => (
                  <Row>
                    <li id={movement.id}>
                      {movement.day} - {movement.value} €
                    </li>
                    <Button
                      size="sm"
                      variant="link"
                      className="position-relative pt-0"
                      onClick={() =>
                        api
                          .delete(`bank-data/?id=` + movement.id, {})
                          .then(data => {
                            this.bankMovementSetup()
                            toast.success('Bank movement successfully removed')
                          })
                          .catch(error => {
                            toast.error('Error removing bank movement')
                          })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        style={{ fill: 'red' }}
                      >
                        <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                      </svg>
                    </Button>
                  </Row>
                ))}
              </ul>
            </div>
          ) : (
            <div
              className="well"
              style={{
                padding: `$spacer`,
                marginTop: '20px',
                textAlign: 'left'
              }}
            >
              <strong> No bank movements for the selected periods</strong>
            </div>
          )}
        </div>
      </div>
    )
  }
}

Detail.propTypes = {
  clients: PropTypes.object,
  clientSlug: PropTypes.string,
  clientData: PropTypes.object,
  isFetching: PropTypes.bool,
  filterValues: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients: state.clients.entities.clients,
    clientData: state.clients.detail.data,
    isFetching:
      state.clients.detail.isFetching[ownProps.match.params.clientSlug],
    filterValues: state.filter.applied
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDetailData: (aggregationLevel, clientSlug, startDate, endDate) =>
      dispatch(
        fetchDetailData(aggregationLevel, clientSlug, startDate, endDate)
      )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Detail)
)
