import * as types from './index'

//action creator
export function setUiFilterField(key, value) {
  return {
    type: types.SET_UI_FILTER_FIELD,
    payload: { key, value }
  }
}

export function applyUiFilter() {
  return {
    type: types.APPLY_UI_FILTER,
    payload: Date.now()
  }
}
