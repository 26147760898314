import { getFirstDayOfThisMonth, getTodayDate } from '../../utils'

export const SET_UI_FILTER_FIELD = 'SET_UI_FILTER_FIELD'
export const APPLY_UI_FILTER = 'APPLY_UI_FILTER'

const initialState = {
  ui: {
    startDate: getFirstDayOfThisMonth(),
    endDate: getTodayDate(),
    aggregationLevel: 'monthly'
  },
  applied: {
    startDate: getFirstDayOfThisMonth(),
    endDate: getTodayDate(),
    aggregationLevel: 'monthly'
  },
  appliedAt: null
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${SET_UI_FILTER_FIELD}`: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.key]: action.payload.value
        }
      }
    }
    case `${APPLY_UI_FILTER}`: {
      const at = action.payload
      return {
        ...state,
        applied: {
          ...state.ui
        },
        appliedAt: at
      }
    }

    default: {
      return state
    }
  }
}
