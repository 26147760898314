import { schema, normalize } from 'normalizr'

export const FETCH_DETAIL_DATA = 'FETCH_DETAIL_DATA'
export const FETCH_LISTING_DATA = 'FETCH_LISTING_DATA'
export const FETCH_CLIENTS = 'FETCH_CLIENTS'

const initialState = {
  entities: {
    clients: {}
  },
  isFetching: false,
  error: {},
  listing: {
    data: {},
    isFetching: {},
    error: {}
  },
  detail: {
    data: {},
    isFetching: {},
    error: {}
  }
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_CLIENTS}_PENDING`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${FETCH_CLIENTS}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    }
    case `${FETCH_CLIENTS}_FULFILLED`: {
      const client = new schema.Entity('clients')

      const normalizedData = normalize(action.payload.data, [client])

      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          clients: normalizedData.entities.clients
        }
      }
    }
    default: {
      //const listingRegex = /FETCH_LISTING_DATA_(\w+)_(\w+)/g
      //const detailRegex = /FETCH_DETAIL_DATA_(\w+)_(\w+)/g
      const listingRegex = /FETCH_LISTING_DATA_([A-Za-z0-9-\s]+)_([A-Za-z0-9-]+)/g
      const detailRegex = /FETCH_DETAIL_DATA_([A-Za-z0-9-\s]+)_([A-Za-z0-9-]+)/g

      const matchListing = listingRegex.exec(action.type)
      const matchDetail = detailRegex.exec(action.type)

      if (matchListing) {
        const [, clientSlug, type] = matchListing

        switch (type) {
          case `PENDING`: {
            return {
              ...state,
              listing: {
                ...state.listing,
                isFetching: {
                  ...state.listing.isFetching,
                  [clientSlug]: true
                }
              }
            }
          }
          case `REJECTED`: {
            return {
              ...state,
              listing: {
                ...state.listing,
                isFetching: {
                  ...state.listing.isFetching,
                  [clientSlug]: false
                },
                error: {
                  ...state.listing.error,
                  [clientSlug]: action.payload
                }
              }
            }
          }
          case `FULFILLED`: {
            return {
              ...state,
              listing: {
                ...state.listing,
                isFetching: {
                  ...state.listing.isFetching,
                  [clientSlug]: false
                },
                data: {
                  ...state.listing.data,
                  [clientSlug]: action.payload.data.data.total
                }
              }
            }
          }
        }
      } else if (matchDetail) {
        const [, clientSlug, type] = matchDetail

        switch (type) {
          case `PENDING`: {
            return {
              ...state,
              detail: {
                ...state.detail,
                isFetching: {
                  ...state.detail.isFetching,
                  [clientSlug]: true
                }
              }
            }
          }
          case `REJECTED`: {
            return {
              ...state,
              detail: {
                ...state.detail,
                isFetching: {
                  ...state.detail.isFetching,
                  [clientSlug]: false
                },
                error: {
                  ...state.detail.error,
                  [clientSlug]: action.payload
                }
              }
            }
          }
          case `FULFILLED`: {
            return {
              ...state,
              detail: {
                ...state.detail,
                isFetching: {
                  ...state.detail.isFetching,
                  [clientSlug]: false
                },
                data: {
                  ...state.detail.data,
                  [clientSlug]: action.payload.data.data
                }
              }
            }
          }
        }
      }
      return state
    }
  }
}
