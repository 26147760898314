import { createStore, applyMiddleware, compose } from 'redux'

import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import promise from 'redux-promise-middleware'

import { rootReducer } from './modules/index'

// configurazione dei middleware
let middleware = [promise, thunkMiddleware]

// loggerMiddleware solo in ambiente di sviluppo
if (process.env.NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger()
  middleware = [...middleware, loggerMiddleware]
} else {
  middleware = [...middleware]
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  )
  return store
}

export default configureStore
