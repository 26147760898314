import React from 'react'
import { api } from '../../utils'
import PageTitle from '../../components/PageTitle'

class AlertsUser extends React.Component {
  state = {
    alerts: [],
    user: [],
    clients: [],
    error: null,
    profile_id: this.props.profile_id,
    username: this.props.username
  }

  componentDidMount() {
    this.user = api.get(`get-user/`).then(data => {
      this.setState({ user: data.data })

      this.alerts = api
        .get(`alerts/?users=` + this.state.user.id)
        .then(data => {
          this.setState({ alerts: data.data })
        })
    })

    this.clients = api.get('clients/').then(data => {
      this.setState({ clients: data.data })
    })
  }

  render() {
    const { alerts, error } = this.state

    return (
      <React.Fragment>
        <div className="w-container">
          <PageTitle title="Active user alerts" />
        </div>
        <div className="w-bg-grey my-4 py-3">
          <div className="w-container">
            <h3 className="w-heading-3 text-right">
              User: {this.state.user.username}
            </h3>
          </div>
        </div>
        <div className="w-container mt-5 pb-4">
          <table className="w-table">
            <thead>
              <tr>
                <th id="client">Client</th>
                <th id="alert">Alert Name</th>
                <th id="message">Error Message</th>
              </tr>
            </thead>
            {error ? <p>{error.message}</p> : null}
            {alerts
              .sort((a, b) => a.client_id - b.client_id)
              .map(alerts => {
                const {
                  client_id,
                  id,
                  alert_name,
                  active,
                  error_message
                } = alerts

                let first
                for (var i = 0; i < this.state.clients.length; i++) {
                  if (this.state.clients[i].id == client_id) {
                    first = this.state.clients[i]
                  }
                }

                if (active) {
                  return (
                    <tr key={id}>
                      <td headers="client">{first.name}</td>
                      <td headers="alert">{alert_name}</td>
                      <td headers="message">{error_message}</td>
                    </tr>
                  )
                }
              })}
          </table>
        </div>
      </React.Fragment>
    )
  }
}

export default AlertsUser
