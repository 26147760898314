import React from 'react'

import ReactTable from 'react-table'

function WidgetTable({ data, columns, sortingOptions = undefined }) {
  return (
    <ReactTable
      key={data.length}
      columns={columns}
      sorted={sortingOptions !== undefined ? sortingOptions : []}
      data={data}
      defaultPageSize={data.length}
      className="-striped -highlight w-reacttable"
    />
  )
}

export default WidgetTable
