import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { transformDateFormat, transformDateForDefaultFilter } from '../../utils'

import PropTypes from 'prop-types'

import { applyUiFilter, setUiFilterField } from '../../modules/filter/actions'
import { getFilterDataSelector } from '../../modules/filter/selectors'

class Filter extends PureComponent {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.appliedAt === null) {
      this.props.applyUiFilter()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const filterSchema = Yup.object().shape({
      startDate: Yup.date().min('01-01-2018'),
      endDate: Yup.date(),
      aggregationLevel: Yup.string().required('Please select your preference')
    })

    return (
      <div id="picker-form" className="mb-5">
        <Formik
          initialValues={{
            startDate: '',
            endDate: '',
            aggregationLevel: 'monthly'
          }}
          onSubmit={this.props.applyUiFilter}
          validationSchema={filterSchema}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            defaultValue
          }) => (
            <form onSubmit={handleSubmit} className="w-form-inline">
              <div className="w-bg-grey pt-3 pb-2">
                <div className="container-fluid text-center">
                  <div className="form-group">
                    <label htmlFor="startDate">Start Date</label>
                    <input
                      id="startDate"
                      type="date"
                      value={transformDateForDefaultFilter(
                        this.props.filterValuesUi.startDate
                      )}
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('startDate', event.target.value)
                        this.props.setUiFilterField(
                          'startDate',
                          transformDateFormat(event.target.value)
                        )
                      }}
                      className={
                        errors.startDate && touched.startDate
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.startDate && touched.startDate && (
                      <div className="input-feedback">{errors.startDate}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="endDate">End Date</label>
                    <input
                      id="endDate"
                      type="date"
                      value={transformDateForDefaultFilter(
                        this.props.filterValuesUi.endDate
                      )}
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('endDate', event.target.value)
                        this.props.setUiFilterField(
                          'endDate',
                          transformDateFormat(event.target.value)
                        )
                      }}
                      className={
                        errors.endDate && touched.endDate
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.endDate && touched.endDate && (
                      <div className="input-feedback">{errors.endDate}</div>
                    )}
                  </div>
                  {this.props.match.params.clientSlug && (
                    <div className="form-group">
                      <label htmlFor="aggregationLevel">
                        Aggregation level
                      </label>
                      <select
                        id="aggregationLevel"
                        value={values.aggregationLevel}
                        onChange={event => {
                          handleChange(event)
                          setFieldValue('aggregationLevel', event.target.value)
                          this.props.setUiFilterField(
                            'aggregationLevel',
                            event.target.value
                          )
                        }}
                        className={
                          errors.aggregationLevel && touched.aggregationLevel
                            ? 'text-input error'
                            : 'text-input'
                        }
                      >
                        <option value="daily" label="daily" />
                        <option value="weekly" label="weekly" />
                        <option value="monthly" label="monthly" defaultValue />
                      </select>
                      {errors.aggregationLevel && touched.aggregationLevel && (
                        <div className="input-feedback">
                          {errors.aggregationLevel}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="form-group">
                    <button type="submit">Filter</button>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    )
  }
}

const filterDataShape = PropTypes.shape({
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  aggregationLevel: PropTypes.string
})

Filter.propTypes = {
  applyUiFilter: PropTypes.func,
  setUiFilterField: PropTypes.func,
  filterData: filterDataShape,
  filterValuesApplied: PropTypes.object,
  filterValuesUi: PropTypes.object
}

const mapStateToProps = state => {
  return {
    filterData: getFilterDataSelector(state),
    appliedAt: state.filter.appliedAt,
    filterValuesApplied: state.filter.applied,
    filterValuesUi: state.filter.ui
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUiFilterField: (key, value, isCompare) =>
      dispatch(setUiFilterField(key, value, isCompare)),
    applyUiFilter: () => dispatch(applyUiFilter())
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Filter)
)
