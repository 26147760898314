import { api } from '../../utils/index'

import * as types from './index'

// action creator
export function fetchClients() {
  return {
    type: `${types.FETCH_CLIENTS}`,
    payload: api.get('clients-light/')
  }
}

export function fetchListingData(clientSlug, startDate, endDate) {
  return {
    type: `${types.FETCH_LISTING_DATA}_` + clientSlug,
    payload: api.get(
      'client-aggregated/?start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&client=' +
        clientSlug
    )
  }
}

export function fetchDetailData(
  aggregationLevel,
  clientSlug,
  startDate,
  endDate
) {
  return {
    type: `${types.FETCH_DETAIL_DATA}_` + clientSlug,
    payload: api.get(
      'client-' +
        aggregationLevel +
        '/?start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&client=' +
        clientSlug
    )
  }
}
