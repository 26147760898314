import { createSelector } from 'reselect'

const getUi = state => state.filter.ui
const getFilterAppliedValues = state => state.filter.applied

export const getFilterDataSelector = createSelector(
  [getUi],
  ui => ({
    ...ui
  })
)

export const getAppliedFilterValuesSelector = createSelector(
  [getFilterAppliedValues],
  values => ({
    startDate: values.startDate,
    endDate: values.endDate,
    aggregationLevel: values.aggregationLevel
  })
)
