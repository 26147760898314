import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import { api } from '../../utils'
import { fetchClients } from '../../modules/clients/actions'

import PageTitle from '../../components/PageTitle'
import { toast } from 'react-toastify'

class NewBankMovement extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (_isEmpty(this.props.clients)) {
      this.props.fetchClients()
    }
  }

  render() {
    const operationSchema = Yup.object().shape({
      client: Yup.number().required('This field is required!'),
      amount: Yup.number().required('This field is required!'),
      date: Yup.date().required(),
      invoiceRef: Yup.string().required('This field is required!')
    })
    return (
      <div className="NewBankMovement text-left">
        <div className="w-container">
          <PageTitle title="New Bank Movement" />
        </div>
        <Formik
          initialValues={{
            client: '',
            amount: '',
            date: '',
            invoiceRef: ''
          }}
          onSubmit={values => {
            api
              .post('bank-data/', {
                client: values.client,
                day: values.date,
                value: values.amount,
                bill_id: values.invoiceRef,
                bill_day: values.date
              })
              .then(data => {
                toast.success('Bank movement added successfully')
              })
              .catch(error => {
                toast.error('Error Creating client')
              })
          }}
          validationSchema={operationSchema}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <div className="w-bg-grey py-4">
              <div className="w-container">
                <form className="w-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="client">Client</label>
                    <select
                      id="client"
                      value={values.client}
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('client', event.target.value)
                      }}
                      className={
                        errors.client && touched.client
                          ? 'form-control error'
                          : 'form-control'
                      }
                    >
                      <option> -- select a client --</option>
                      {Object.values(this.props.clients).map(
                        (client, index) => (
                          <option
                            key={client.id}
                            value={client.id}
                            label={client.name}
                          />
                        )
                      )}
                    </select>
                    {errors.client && touched.client && (
                      <div className="input-feedback">{errors.client}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="amount">Amount</label>
                    <input
                      id="amount"
                      autocomplete="off"
                      value={values.amount}
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('amount', event.target.value)
                      }}
                      className={
                        errors.amount && touched.amount
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.aggregationLevel && touched.aggregationLevel && (
                      <div className="input-feedback">
                        {errors.aggregationLevel}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="date">Date</label>
                    <input
                      id="date"
                      type="date"
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('date', event.target.value)
                      }}
                      value={values.date}
                      className={
                        errors.date && touched.date
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.date && touched.date && (
                      <div className="input-feedback">{errors.date}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="date">Invoice referral</label>
                    <input
                      id="invoiceRef"
                      placeholder="NNN/DDMMYYYY"
                      onChange={event => {
                        handleChange(event)
                        setFieldValue('invoiceRef', event.target.value)
                      }}
                      value={values.invoiceRef}
                      className={
                        errors.invoiceRef && touched.invoiceRef
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.invoiceRef && touched.invoiceRef && (
                      <div className="input-feedback">{errors.invoiceRef}</div>
                    )}
                  </div>
                  <div className="w-submit-wrap">
                    <button type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        />
      </div>
    )
  }
}

NewBankMovement.propTypes = {
  clients: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients: state.clients.entities.clients
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchClients: () => dispatch(fetchClients())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBankMovement)
