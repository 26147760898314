import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import 'bootstrap/dist/css/bootstrap.css'
import './index.scss'

import { makeMainRoutes } from 'routes'
const routes = makeMainRoutes()

ReactDOM.render(routes, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
