import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { fetchClients } from '../../modules/clients/actions'
import connect from 'react-redux/es/connect/connect'

class Topbar extends Component {
  constructor(props) {
    super(props)

    this.handleLogoutClick = this.handleLogoutClick.bind(this)
  }

  componentDidMount() {
    this.props.fetchClients()
  }

  handleLogoutClick(e) {
    e.preventDefault()
    this.props.onLogout()
  }

  render() {
    return (
      <nav className="w-topbar">
        <div className="w-navbar">
          <div className="w-navbar-item w-navmobile-trigger">
            <button>
              <img src="/images/svg/menu.svg" alt="Menu" />
            </button>
          </div>
          <div className="w-navbar-item w-logo">
            <Link to="/">
              <img id="gmp_logo" src="/apple-touch-icon-76x76.png" alt="Logo" />
              GMP Monitor
            </Link>
          </div>
          <div id="w-nav-mobile" className="w-navbar-item w-nav">
            <div className="w-nav-item">
              <Link to="/">Overview</Link>
            </div>

            <div className="w-nav-item w-dropdown">
              <a className="w-dropdown-toggle" href="#">
                Client detail
              </a>
              <div className="w-dropdown-menu">
                {Object.values(this.props.clients)
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((client, index) => {
                    return (
                      <Link to={'/' + client.slug} key={client.slug}>
                        {client.name}
                      </Link>
                    )
                  })}
              </div>
            </div>

            <div className="w-nav-item">
              <Link to="/add_bank_op">New Bank Movement</Link>
            </div>
            <div className="w-nav-item">
              <Link to="/alerts_user">User Alerts</Link>
            </div>
          </div>
          <div className="w-navbar-item w-utils">
            {this.props.isAuthenticated && (
              <a href="#" onClick={this.handleLogoutClick}>
                <img src="/images/svg/logout.svg" />
              </a>
            )}
          </div>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients: state.clients.entities.clients
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchClients: () => dispatch(fetchClients())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Topbar)
