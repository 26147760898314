import React from 'react'

import loader from '../images/loader.gif'

function Loading({ children }) {
  return (
    <div className="u-loader-icon">
      <img alt="loading" src={loader} />
    </div>
  )
}

export default Loading
