import React from 'react'

function PageTitle({ title }) {
  return (
    <div className="w-page-title">
      <h1>{title}</h1>
    </div>
  )
}

export default PageTitle
