import { Formik } from 'formik'
import * as Yup from 'yup'
import React, { Component } from 'react'
import { api } from '../utils/index'
import PageTitle from './PageTitle'

class UpdateNotification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notification: [],
      alert: [],
      user: [],
      NotificationID: this.props.location.search.replace('?id=', ''),
      Active: ''
    }
  }

  componentDidMount() {
    this.notification = api
      .get('notifications/' + this.state.NotificationID + '/')
      .then(data => {
        this.setState({ notification: data.data })

        this.alert = api
          .get(`alerts/` + this.state.notification.alert + '/')
          .then(data => {
            this.setState({ alert: data.data })
          })
      })

    this.user = api.get(`get-user/`).then(data => {
      this.setState({ user: data.data })
    })
  }

  render() {
    const addNotificationSchema = Yup.object().shape({
      disable_notification: Yup.boolean().required('This field is required!')
    })

    let check = null
    let check2 = null

    if (this.state.notification.disable_notification) {
      check = 'checked'
      check2 = null
    } else {
      check = null
      check2 = 'checked'
    }

    const SpaceLine = {
      height: '20px'
    }
    const color = {
      color: 'red'
    }

    var checkone = {}

    let NAME = this.state.alert.alert_name

    if (this.state.user.id === this.state.notification.user_id) {
      return (
        <div>
          <PageTitle title="Update Notification" />
          <p style={SpaceLine} />
          <h3>L'alert "{NAME}" è:</h3>
          <h3 style={color}>
            {' '}
            {String(
              this.state.notification.disable_notification
                ? 'Non Attivo'
                : 'Attivo'
            )}
          </h3>
          <p style={SpaceLine} />
          <Formik
            initialValues={{
              disable_notification: null
            }}
            onSubmit={values => {
              api
                .put('notifications/' + this.state.NotificationID + '/', {
                  disable_notification: values.disable_notification,
                  alert: this.state.notification.alert
                })
                .then()
              {
                this.setState(prevState => {
                  return {
                    notification: {
                      ...prevState.notification,
                      disable_notification:
                        values.disable_notification === 'true'
                    }
                  }
                })
              }
            }}
            validationSchema={addNotificationSchema}
            validateOnBlur={true}
            render={({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="Disabled">Imposta Stato:</label>
                  <br />
                  <span>Attivato</span>
                  <input
                    type="radio"
                    name="disable_notification"
                    value={(checkone = false)}
                    onChange={handleChange}
                    checked={check2}
                  />
                  <p />
                  <span>Disattivato</span>
                  <input
                    type="radio"
                    name="disable_notification"
                    value={(checkone = true)}
                    onChange={handleChange}
                    checked={check}
                  />
                  {errors.disable_notification &&
                    touched.disable_notification && (
                      <div className="input-feedback">
                        {errors.disable_notification}
                      </div>
                    )}
                </div>

                <div>
                  {checkone
                    ? values.disable_notification === 'True'
                    : values.disable_notification === 'False'}
                </div>

                <p style={SpaceLine} />

                <button type="submit">Submit</button>
              </form>
            )}
          />
        </div>
      )
    } else {
      return (
        <div>
          <p style={SpaceLine} />
          <p style={SpaceLine} />
          <h1>Error: User Not authorized</h1>
        </div>
      )
    }
  }
}

export default UpdateNotification
