import React, { Component } from 'react'
import Topbar from './containers/TopBar/Topbar'
import Footer from './components/Footer'
import { Route, Switch } from 'react-router-dom'

import auth from './auth/service'

import Listing from './containers/Listing/Listing'
import Detail from './containers/Detail/Detail'
import Admin from './containers/Admin/Admin'
import NewBankMovement from './containers/NewBankMovement/NewBankMovement'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-table/react-table.css'
import './App.css'
import EditCreateClient from './components/EditCreateClient'
import UpdateNotification from './components/UpdateNotification'
import AlertsUser from './containers/AlertsUser/AlertsUser'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-K6R85DS',
}

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.handleLogout = this.handleLogout.bind(this)
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  handleLogout() {
    auth.logout()
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize(tagManagerArgs)
      if (localStorage.getItem('isLoggedIn') === 'true') {
        auth.renewSession().then(() => {
          if (!auth.userProfile) {
            auth.getProfile().then(() => {
              this.setState({ profile: auth.userProfile })
            })
          } else {
            this.setState({ profile: auth.userProfile })
          }
        })
      }
    }
  }

  render() {
    const { isAuthenticated } = auth
    const { profile } = this.state

    return (
      <div className="App">
        <div className="w-main">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />
          <Topbar
            isAuthenticated={isAuthenticated}
            onLogout={this.handleLogout}
          />

          <Switch>
            <Route exact path="/" render={props => <Listing {...props} />} />
            <Route
              path="/add_bank_op"
              render={props => <NewBankMovement {...props} />}
            />
            {/*<Route*/}
            {/*  path="/add_new_client"*/}
            {/*  render={props => <AddNewClient {...props} />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="/edit_client/:clientSlug"*/}
            {/*  render={props => <EditClient {...props} />}*/}
            {/*/>*/}
            <Route
              path="/client/:clientSlug"
              render={props => <EditCreateClient {...props} />}
            />
            <Route
              path="/update_notification"
              render={props => <UpdateNotification {...props} />}
            />
            <Route
              path="/alerts_user"
              render={props => <AlertsUser {...props} />}
            />
            <Route path="/admin" render={props => <Admin {...props} />} />
            <Route
              path="/:clientSlug"
              render={props => <Detail {...props} />}
            />
          </Switch>
        </div>
        <Footer />
      </div>
    )
  }
}

export default App
